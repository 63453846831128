import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
  Tab,
  Nav,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import moments from "moment-timezone";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import "./Pagination.css";
import CurrencyInput from "react-currency-input-field";
import NotificationAlert from "react-notification-alert";
import {
  getPoProses,
  getPoSelesai,
  updatePo,
  exportPo,
  updateStatusBayarPo,
  cancelPo,
  getRekeningDropdown,
} from "stores";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";

function Po() {
  const dispatch = useDispatch();
  const po = useSelector((state) => state.poReducer);
  const auth = useSelector((state) => state.authReducer);
  const pengajuanBiaya = useSelector((state) => state.pengajuanReducer);
  const [listPoProses, setListPoProses] = React.useState([]);
  const [listPoSelesai, setListPoSelesai] = React.useState([]);
  const notificationAlertRef = React.useRef(null);

  const [modal, setModal] = React.useState(false);
  const [modalUpdatePo, setModalUpdatePo] = React.useState(false);
  const [modalStor, setModalStor] = React.useState(false);
  const [statusStor, setStatusStor] = React.useState("");
  const [statusStorValidasi, setStatusStorValidasi] = React.useState(false);
  const [detailPo, setDetailPo] = React.useState(null);
  const [pending, setPending] = React.useState(false);
  const [selesai, setSelesai] = React.useState(1);

  const [totalPerHalamanProses, setTotalPerHalamanProses] = React.useState(10);
  const [pageProses, setPageProses] = React.useState(0);
  const [searchTextProses, setSearchTextProses] = React.useState("");

  const [totalPerHalamanSelesai, setTotalPerHalamanSelesai] =
    React.useState(10);
  const [pageSelesai, setPageSelesai] = React.useState(0);
  const [searchTextSelesai, setSearchTextSelesai] = React.useState("");
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0)
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const [idRekeningAsal, setIdRekeningAsal] = React.useState("");
  const [validasiRekeningAsal, setValidasiRekeningAsal] = React.useState(false);
  const [listRekening, setListRekening] = React.useState([]);

  const [dokumen, setDokumen] = React.useState([]);
  const [dokumenValidasi, setDokumenValidasi] = React.useState(false);

  function handleFileChange(e) {
    // const selectedFiles = Array.from(e.target.files);
    setDokumen([...dokumen, ...Array.from(e.target.files)]);
    setDokumenValidasi(false);
  }

  const removeDokumen = (index) => {
    setDokumen(dokumen.filter((_, i) => i !== index));
  };
  const exportAllPo = async () => {
    Swal.fire({
      title: "Loading...!",
      text: "Download data PO sedng berlangsung,tunggu proses hingga selesai!",
      icon: "warning",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    exportPo(dispatch, {
      tglAwal: moments
        .tz(new Date(tglAwal), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
      tglAkhir: moments
        .tz(new Date(tglAkhir), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
    }).then((respon) => {
      if (respon.status === 200) {
        const url = window.URL.createObjectURL(new Blob([respon.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Export PO-${moments
            .tz(tglAwal, "Asia/Jakarta")
            .format("DD-MM-YYYY")} sampai ${moments
            .tz(tglAkhir, "Asia/Jakarta")
            .format("DD-MM-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        Swal.fire({
          title: "Download Berhasil",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !respon.data ? "Gagal download data PO" : respon.data.message,
        });
      }
    });
  };
  let incSelesai = () => {
    if (selesai + 1 > detailPo.qty - detailPo.qtySelesai) {
      null;
    } else {
      setSelesai(selesai + 1);
    }
  };
  let decSelesai = () => {
    if (selesai - 1 === 0) {
      null;
    } else {
      setSelesai(selesai - 1);
    }
  };

  const handleSearchProses = (e) => {
    setSearchTextProses(e.target.value);
  };
  const handleClearSearchProses = () => {
    setSearchTextProses("");
  };

  const handleSearchSelesai = (e) => {
    setSearchTextSelesai(e.target.value);
  };
  const handleClearSearchSelesai = () => {
    setSearchTextSelesai("");
  };
  const optionsProses = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const optionsSelesai = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];

  const handlePageSizeChangeProses = (selectedOption) => {
    setTotalPerHalamanProses(selectedOption.value);
  };
  const handlePageSizeChangeSelesai = (selectedOption) => {
    setTotalPerHalamanSelesai(selectedOption.value);
  };
  const customStyles = {
    rows: {
      style: {
        marginTop: 5,
        marginBottom: 5,
        // height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.qty != row.qtySelesai,
      style: {
        backgroundColor: "rgba(255, 3, 3, 0.15)",
        color: "#000000",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const columnsProses = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + po.listPoProses.pagingCounter,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  setModal(true);
                  setDetailPo(row);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              {(auth.responLogin && auth.responLogin.role === "Super Admin") ||
              (auth.responLogin && auth.responLogin.role === "Admin") ||
              (auth.responLogin && auth.responLogin.role === "Management") ||
              (auth.responLogin && auth.responLogin.role === "Logistik") ? (
                <div
                  onClick={() => {
                    row.statusPo === "Selesai" ? null : setModalUpdatePo(true);
                    setDetailPo(row);
                  }}
                  style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor:
                      row.statusProduk === "Selesai" ? "unset" : "pointer",
                    margin: 10,
                  }}
                >
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="tooltip-48903503">Update Selesai</Tooltip>
                    }
                  >
                    <i
                      style={{
                        color:
                          row.statusProduk === "Selesai" ? "grey" : "#478964",
                        fontSize: 16,
                      }}
                      className="fas fa-edit"
                    ></i>
                  </OverlayTrigger>
                </div>
              ) : null}

              {(auth.responLogin &&
                auth.responLogin.role === "Super Admin" &&
                row.statusPo === "Proses") ||
              (auth.responLogin &&
                auth.responLogin.role === "Management" &&
                row.statusPo === "Proses") ? (
                <div
                  onClick={() => {
                    Swal.fire({
                      title: `Apakah anda yakin untuk meng-cancel PO kloter ${row.kloter} ini?`,
                      text: "Pastikan PO yang anda pilih sudah benar!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Ok!",
                      cancelButtonText: "Batal!",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        Swal.fire({
                          title: "Loading...!",
                          text: "Proses Cancel sedang dilakukan,tunggu proses hingga selesai!",
                          icon: "warning",
                          allowOutsideClick: false,
                          didOpen: () => {
                            Swal.showLoading();
                          },
                        });

                        cancelPo({ idPo: row.idPo }).then((response) => {
                          if (response.status === 200) {
                            getPoProses(dispatch, {
                              cari: searchTextProses,
                              page: pageProses + 1,
                              limit: totalPerHalamanProses,
                            });
                            getPoSelesai(dispatch, {
                              cari: searchTextSelesai,
                              page: pageSelesai + 1,
                              limit: totalPerHalamanSelesai,
                            });
                            Swal.fire({
                              title: response.data.message,
                              icon: "success",
                              showConfirmButton: false,
                              timer: 1500,
                            });
                          } else {
                            getPoProses(dispatch, {
                              cari: searchTextProses,
                              page: pageProses + 1,
                              limit: totalPerHalamanProses,
                            });
                            getPoSelesai(dispatch, {
                              cari: searchTextSelesai,
                              page: pageSelesai + 1,
                              limit: totalPerHalamanSelesai,
                            });
                            Swal.fire({
                              icon: "error",
                              title: "Oops...",
                              text: !response.data
                                ? "Gagal mengcancel order"
                                : response.data.message,
                            });
                          }
                        });
                      }
                    });
                  }}
                  style={{
                    width: 25,
                    height: 25,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    margin: 10,
                  }}
                >
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-48903503">Cancel PO</Tooltip>}
                  >
                    <i
                      style={{
                        color: "#FF0100",
                        fontSize: 16,
                      }}
                      className="fas fa-trash"
                    ></i>
                  </OverlayTrigger>
                </div>
              ) : null}
            </div>
          </>
        );
      },
    },
    {
      name: "Kloter",
      width: "100px",
      center: true,
      selector: (row) => row.kloter,
    },
    {
      name: "ID PO",
      width: "200px",
      center: true,
      selector: (row) => row.idPo,
    },
    {
      name: "Status Pengerjaan",
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusPo === "Proses" ? (
          <div
            style={{
              backgroundColor: "rgba(36,204,239,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,239)",
            }}
          >
            <b style={{ color: "rgb(36,204,239)" }}>Diproses</b>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "rgba(36,204,23,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,23)",
            }}
          >
            <b style={{ color: "rgb(36,204,23)" }}>Selesai</b>
          </div>
        ),
    },
    {
      name: "Kode Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.kodeProduk,
    },
    {
      name: "Nama Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.namaProduk,
    },
    {
      name: "Kapasitas",
      width: "200px",
      center: true,
      selector: (row) => row.produk.kapasitas,
    },
    {
      name: "Tipe Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.produk.tipe,
    },
    {
      name: "Jumlah PO",
      width: "200px",
      center: true,
      selector: (row) => row.qty,
    },
    {
      name: "Jumlah Selesai",
      width: "200px",
      center: true,
      selector: (row) => row.qtySelesai,
    },
    {
      name: "Tanggal Order",
      width: "200px",
      center: true,
      selector: (row) =>
        moments.tz(new Date(row.tglOrder), "Asia/Jakarta").format("DD-MM-YYYY"),
    },
    {
      name: "Deadline",
      width: "200px",
      center: true,
      selector: (row) =>
        !row.tglDeadline ? (
          <b>-</b>
        ) : (
          moments
            .tz(new Date(row.tglDeadline), "Asia/Jakarta")
            .format("DD-MM-YYYY")
        ),
    },
    {
      name: "Tanggal Selesai",
      width: "200px",
      center: true,
      selector: (row) =>
        !row.tglSelesai || row.tglSelesai === "" ? (
          <b>-</b>
        ) : (
          moments
            .tz(new Date(row.tglSelesai), "Asia/Jakarta")
            .format("DD-MM-YYYY")
        ),
    },

    // {
    //   name: "Jumlah Pesan",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.qty,
    // },
    // {
    //   name: "Jumlah Selesai",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.qtySelesai,
    // },
    // {
    //   name: "Instansi",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.order.customerInstansi,
    // },
    // {
    //   name: "Nama Customer",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.order.namaCustomer,
    // },
  ];

  const columnsSelesai = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + po.listPoSelesai.pagingCounter,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  setModal(true);
                  setDetailPo(row);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              {(auth.responLogin && auth.responLogin.role === "Super Admin") ||
              (auth.responLogin && auth.responLogin.role === "Management") ? (
                <>
                  <div
                    onClick={() => {
                      if (row.statusPembayaran === false) {
                        setModalStor(true);
                        setDetailPo(row);
                      } else {
                        null;
                      }
                    }}
                    style={{
                      width: 25,
                      height: 25,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                      margin: 10,
                      cursor:
                        row.statusPembayaran === false ? "pointer" : "unset",
                    }}
                  >
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-48903503">
                          Update Status Pembayaran{" "}
                        </Tooltip>
                      }
                    >
                      <i
                        style={{
                          color:
                            row.statusPembayaran === false ? "#478964" : "gray",
                          fontSize: 16,
                        }}
                        className="fas fa-edit"
                      ></i>
                    </OverlayTrigger>
                  </div>
                </>
              ) : null}
              {/* <div
                onClick={() => {
                  // row.statusProduk === "Selesai"
                  //   ? null
                  //   : !row.tglAwalPengerjaan
                  //   ? Swal.fire({
                  //       title: "Tidak Dapat Update Data",
                  //       text: "Data tidak dapat diupdate karena belum memiliki invoice",
                  //       icon: "warning",
                  //     })
                  //   : setModalUpdatePl(true);
                  // setDetailPl(row);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: row.statusProduk === "Selesai" ? "unset" : "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-48903503">Update Selesai</Tooltip>
                  }
                >
                  <i
                    style={{
                      color:
                        row.statusProduk === "Selesai" ? "grey" : "#478964",
                      fontSize: 16,
                    }}
                    className="fas fa-edit"
                  ></i>
                </OverlayTrigger>
              </div> */}
            </div>
          </>
        );
      },
    },
    {
      name: "Kloter",
      width: "100px",
      center: true,
      selector: (row) => row.kloter,
    },
    {
      name: "ID PO",
      width: "200px",
      center: true,
      selector: (row) => row.idPo,
    },
    {
      name: "Status Pengerjaan",
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusPo === "Proses" ? (
          <div
            style={{
              backgroundColor: "rgba(36,204,239,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,239)",
            }}
          >
            <b style={{ color: "rgb(36,204,239)" }}>Diproses</b>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "rgba(36,204,23,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,23)",
            }}
          >
            <b style={{ color: "rgb(36,204,23)" }}>Selesai</b>
          </div>
        ),
    },

    {
      name: "Status Pembayaran",
      omit:
        (auth.responLogin && auth.responLogin.role === "Super Admin") ||
        (auth.responLogin && auth.responLogin.role === "Management")
          ? false
          : true,
      width: "200px",
      center: true,
      selector: (row) =>
        row.statusPembayaran === true ? (
          <div style={{ color: "green" }}>
            <b>Sudah Dibayar</b>
          </div>
        ) : (
          <div style={{ color: "red" }}>
            <b>Belum Dibayar</b>
          </div>
        ),
    },
    {
      name: "Kode Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.kodeProduk,
    },
    {
      name: "Nama Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.namaProduk,
    },
    {
      name: "Kapasitas",
      width: "200px",
      center: true,
      selector: (row) => row.produk.kapasitas,
    },
    {
      name: "Tipe Mesin",
      width: "200px",
      center: true,
      selector: (row) => row.produk.tipe,
    },
    {
      name: "Jumlah PO",
      width: "200px",
      center: true,
      selector: (row) => row.qty,
    },
    {
      name: "Jumlah Selesai",
      width: "200px",
      center: true,
      selector: (row) => row.qtySelesai,
    },
    {
      name: "Tanggal Order",
      width: "200px",
      center: true,
      selector: (row) =>
        moments.tz(new Date(row.tglOrder), "Asia/Jakarta").format("DD-MM-YYYY"),
    },
    {
      name: "Deadline",
      width: "200px",
      center: true,
      selector: (row) =>
        !row.tglDeadline ? (
          <b>-</b>
        ) : (
          moments
            .tz(new Date(row.tglDeadline), "Asia/Jakarta")
            .format("DD-MM-YYYY")
        ),
    },
    {
      name: "Tanggal Selesai",
      width: "200px",
      center: true,
      selector: (row) =>
        !row.tglSelesai || row.tglSelesai === "" ? (
          <b>-</b>
        ) : (
          moments
            .tz(new Date(row.tglSelesai), "Asia/Jakarta")
            .format("DD-MM-YYYY")
        ),
    },

    // {
    //   name: "Jumlah Pesan",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.qty,
    // },
    // {
    //   name: "Jumlah Selesai",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.qtySelesai,
    // },
    // {
    //   name: "Instansi",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.order.customerInstansi,
    // },
    // {
    //   name: "Nama Customer",
    //   width: "200px",
    //   center: true,
    //   selector: (row) => row.order.namaCustomer,
    // },
  ];

  const history = useHistory();
  const kirimUpdatePo = async () => {
    Swal.fire({
      title: `Apakah anda yakin untuk mengupdate selesai?`,
      text: `${detailPo.produk.kodeProduk}-${detailPo.produk.namaProduk} kloter ${detailPo.kloter}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Update!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading...!",
          text: "Update data sedang berlangsung,tunggu proses hingga selesai!",
          icon: "warning",
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        updatePo(dispatch, {
          idPo: detailPo.idPo,
          selesai: selesai,
          idProduk: detailPo.idProduk,
        }).then((response) => {
          setModalUpdatePo(false);
          getPoProses(dispatch, {
            cari: searchTextProses,
            page: pageProses + 1,
            limit: totalPerHalamanProses,
          });
          getPoSelesai(dispatch, {
            cari: searchTextSelesai,
            page: pageSelesai + 1,
            limit: totalPerHalamanSelesai,
          });
          Swal.fire({
            title: response.data.message,
            icon: "success",
            showConfirmButton: false,
            timer: 1500,
          });
        });
      }
    });
  };

  React.useEffect(() => {
    let tmp =
      po.listPoProses.data &&
      po.listPoProses.data.map((val, index) => {
        return {
          ...val,
        };
      });

    setListPoProses(tmp);

    if (po.listPoProses.length != 0) {
      setPending(false);
    }
  }, [po.listPoProses]);

  React.useEffect(() => {
    let tmp =
      po.listPoSelesai.data &&
      po.listPoSelesai.data.map((val, index) => {
        return {
          ...val,
        };
      });

    setListPoSelesai(tmp);

    if (po.listPoSelesai.length != 0) {
      setPending(false);
    }
  }, [po.listPoSelesai]);

  React.useEffect(() => {
    getPoProses(dispatch, {
      cari: searchTextProses,
      page: pageProses + 1,
      limit: totalPerHalamanProses,
    });
  }, [pageProses, totalPerHalamanProses, searchTextProses]);

  React.useEffect(() => {
    getPoSelesai(dispatch, {
      cari: searchTextSelesai,
      page: pageSelesai + 1,
      limit: totalPerHalamanSelesai,
    });
  }, [pageSelesai, totalPerHalamanSelesai, searchTextSelesai]);

  const updateStor = () => {
    if (statusStor === "") {
      setStatusStorValidasi(true);
    } else if (idRekeningAsal === "") {
      setValidasiRekeningAsal(true);
    } else if (dokumen.length === 0) {
      setDokumenValidasi(true);
    } else {
      Swal.fire({
        title: `Apakah anda yakin untuk mengupdate status pembayaran PO?`,
        text: `${detailPo.idPo}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Update!",
      }).then((result) => {
        if (result.isConfirmed) {
          // let dataKirim = {
          //   idPo: detailPo.idPo,
          //   statusPembayaran: statusStor.value,
          //   dateSetor: moments
          //     .tz(new Date(), "Asia/Jakarta")
          //     .format("YYYY-MM-DD"),
          // };
          let formData = new FormData();
          formData.append("idPo", detailPo.idPo);
          formData.append("statusPembayaran", statusStor.value);
          formData.append("nominalPengajuan", detailPo.totalHarga);
          formData.append(
            "dateLunas",
            moments.tz(new Date(), "Asia/Jakarta").format("YYYY-MM-DD")
          );
          formData.append("idRekening", idRekeningAsal.value);
          dokumen.map((val, index) => {
            formData.append("dokumen", val);
          });
          Swal.fire({
            title: "Loading...!",
            text: "Update status pembayaran sedang berlangsung,tunggu proses hingga selesai!",
            icon: "warning",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          updateStatusBayarPo(formData).then((response) => {
            if (response.data.status === 200) {
              setModalStor(false);
              setPending(false);
              setStatusStor("");
              setStatusStorValidasi(false);
              setDetailPo(null);
              getPoProses(dispatch, {
                cari: searchTextProses,
                page: pageProses + 1,
                limit: totalPerHalamanProses,
              });
              getPoSelesai(dispatch, {
                cari: searchTextSelesai,
                page: pageSelesai + 1,
                limit: totalPerHalamanSelesai,
              });
              Swal.fire({
                title: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              setPending(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: !response.data
                  ? "Gagal melakukan update"
                  : response.data.message,
              });
            }
          });
        }
      });
    }
  };

  React.useEffect(() => {
    getRekeningDropdown(dispatch);
  }, []);

  React.useEffect(() => {
    let tmpRekening =
      pengajuanBiaya.rekeningDropdown.data &&
      pengajuanBiaya.rekeningDropdown.data.map((val, index) => {
        return {
          value: val.idRekening,
          label: val.namaRekening === "LAINNYA" ? "-" : val.namaRekening,
        };
      });

    setListRekening(tmpRekening);
  }, [pengajuanBiaya.rekeningDropdown]);

  const notify = (val) => {
    var type = val.code === 0 ? "info" : "danger";

    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b style={{ color: "#000000" }}>{val.message}</b>
          </div>
        </div>
      ),
      type: type,
      icon: val.code === 0 ? "fas fa-box-open" : "fas fa-times-circle",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Row>
        <Col md={7}>
          <Row>
            <Col>
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                Tanggal Awal
              </div>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(0, 0, 0, 0),
                      setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                  }}
                  value={tglAwal}
                  initialValue={tglAwal}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                ></ReactDatetime>
              </Form.Group>
            </Col>
            <Col>
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                Tanggal Akhir
              </div>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(23, 59, 0, 0),
                      setTglAkhir(e.toDate().setHours(23, 59, 0, 0));
                  }}
                  value={tglAkhir}
                  initialValue={tglAkhir}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                ></ReactDatetime>
              </Form.Group>
            </Col>
            <Col
              style={{
                alignContent: "center",
                paddingRight: 0,
                paddingLeft: 0,
              }}
            >
              <Button
                style={{
                  marginLeft: 20,
                  marginRight: 0,
                  marginBottom: 0,
                  backgroundColor: "rgba(40,118,74,1)",
                  borderColor: "rgba(40,118,74,1)",
                }}
                onClick={() => {
                  exportAllPo();
                }}
              >
                <i
                  className="fas fa-file-excel"
                  style={{ marginRight: 10 }}
                ></i>
                Export PO
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Tab.Container id="icons-tabs-example" defaultActiveKey="info-icons">
        <Nav role="tablist" variant="tabs">
          <Nav.Item>
            <Nav.Link eventKey="info-icons">
              <i className="fas fa-box-open"></i> PO Proses
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="account-icons">
              <i className="fas fa-store"></i> PO Selesai
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane eventKey="info-icons">
            <Container fluid>
              <Row
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <Col>
                  <div style={{ fontSize: 25, fontWeight: "bold" }}>
                    Purchase Order Proses
                  </div>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  {/* <Button
              style={{ marginLeft: 20, marginBottom: 0 }}
              onClick={() => {
                setModal(true);
              }}
            >
              Tambah Produk
            </Button> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <div>
                      <InputGroup>
                        <Form.Control
                          style={{
                            paddingLeft: "10px",
                            width: "300px",
                          }}
                          type="text"
                          value={searchTextProses}
                          onChange={handleSearchProses}
                          placeholder="Kloter/Nama Produk/Kode Produk"
                        />
                        <InputGroup.Append>
                          <InputGroup.Text
                            style={{ cursor: "pointer" }}
                            onClick={handleClearSearchProses}
                          >
                            <i class="fas fa-times"></i>
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                  </div>
                </Col>
              </Row>

              {/* <Row>
          <Col>
            <Button
              onClick={() => {
                setModalImport(true);
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(40,118,74,1)",
                borderColor: "rgba(40,118,74,1)",
              }}
            >
              <i className="fas fa-file-excel" style={{ marginRight: 10 }}></i>
              Import Excel
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                  "_blank"
                );
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(64,123,255,1)",
                borderColor: "rgba(64,123,255,1)",
                marginLeft: 10,
              }}
            >
              <i
                className="fas fa-cloud-download-alt"
                style={{ marginRight: 10 }}
              ></i>
              Download Format Import
            </Button>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Nama Mesin/Kode Mesin"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row> */}

              <Row>
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <DataTable
                        columns={columnsProses}
                        data={listPoProses}
                        //   pagination
                        customStyles={customStyles}
                        paginationPerPage={totalPerHalamanProses}
                        //   onChangePage={handlePageChange}
                        progressPending={pending}
                        // conditionalRowStyles={conditionalRowStyles}
                      />

                      <div style={{ marginTop: 35 }}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col>
                            <Row style={{ alignItems: "center" }}>
                              <div
                                style={{
                                  paddingRight: 0,
                                  marginLeft: 20,
                                  marginRight: 10,
                                }}
                              >
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    fontSize: 14,
                                  }}
                                >
                                  Rows per page
                                </label>
                              </div>
                              <Col sm="3" style={{ paddingLeft: 0 }}>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  name="Pilih Client"
                                  value={optionsProses.find(
                                    (option) =>
                                      option.value === totalPerHalamanProses
                                  )}
                                  onChange={handlePageSizeChangeProses}
                                  options={optionsProses}
                                  placeholder="Rows per page"
                                />
                              </Col>
                            </Row>
                          </Col>

                          <Col>
                            <ReactPaginate
                              forcePage={pageProses}
                              containerClassName="paginations justify-content-end"
                              previousClassName="pages-items-containers"
                              previousLinkClassName="pages-links-labels"
                              nextClassName="pages-items-containers"
                              nextLinkClassName="pages-links-labels"
                              breakClassName="pages-items-containers"
                              pageCount={po.listPoProses.totalPages}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={1}
                              pageLinkClassName="pages-links-labels"
                              pageClassName="pages-items-containers"
                              activeClassName="actives"
                              activeLinkClassName="activess"
                              nextLabel=" > "
                              previousLabel=" < "
                              initialPage={pageProses}
                              disableInitialCallback={true}
                              onPageChange={(val) => {
                                setPageProses(val.selected);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Tab.Pane>
          <Tab.Pane eventKey="account-icons">
            <Container fluid>
              <Row
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <Col>
                  <div style={{ fontSize: 25, fontWeight: "bold" }}>
                    Purchase Order Selesai
                  </div>
                </Col>
                <Col style={{ textAlign: "right" }}>
                  {/* <Button
              style={{ marginLeft: 20, marginBottom: 0 }}
              onClick={() => {
                setModal(true);
              }}
            >
              Tambah Produk
            </Button> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      marginBottom: 20,
                    }}
                  >
                    <div>
                      <InputGroup>
                        <Form.Control
                          style={{
                            paddingLeft: "10px",
                            width: "300px",
                          }}
                          type="text"
                          value={searchTextSelesai}
                          onChange={handleSearchSelesai}
                          placeholder="Kloter/Nama Produk/Kode Produk"
                        />
                        <InputGroup.Append>
                          <InputGroup.Text
                            style={{ cursor: "pointer" }}
                            onClick={handleClearSearchSelesai}
                          >
                            <i class="fas fa-times"></i>
                          </InputGroup.Text>
                        </InputGroup.Append>
                      </InputGroup>
                    </div>
                  </div>
                </Col>
              </Row>
              {/* <Row>
          <Col>
            <Button
              onClick={() => {
                setModalImport(true);
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(40,118,74,1)",
                borderColor: "rgba(40,118,74,1)",
              }}
            >
              <i className="fas fa-file-excel" style={{ marginRight: 10 }}></i>
              Import Excel
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                  "_blank"
                );
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(64,123,255,1)",
                borderColor: "rgba(64,123,255,1)",
                marginLeft: 10,
              }}
            >
              <i
                className="fas fa-cloud-download-alt"
                style={{ marginRight: 10 }}
              ></i>
              Download Format Import
            </Button>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Nama Mesin/Kode Mesin"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row> */}

              <Row>
                <Col md="12">
                  <Card>
                    <Card.Body>
                      <DataTable
                        columns={columnsSelesai}
                        data={listPoSelesai}
                        //   pagination
                        customStyles={customStyles}
                        paginationPerPage={totalPerHalamanSelesai}
                        //   onChangePage={handlePageChange}
                        progressPending={pending}
                        // conditionalRowStyles={conditionalRowStyles}
                      />

                      <div style={{ marginTop: 35 }}>
                        <Row
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col>
                            <Row style={{ alignItems: "center" }}>
                              <div
                                style={{
                                  paddingRight: 0,
                                  marginLeft: 20,
                                  marginRight: 10,
                                }}
                              >
                                <label
                                  style={{
                                    fontWeight: "bold",
                                    textTransform: "none",
                                    fontSize: 14,
                                  }}
                                >
                                  Rows per page
                                </label>
                              </div>
                              <Col sm="3" style={{ paddingLeft: 0 }}>
                                <Select
                                  className="react-select primary"
                                  classNamePrefix="react-select"
                                  name="Pilih Client"
                                  value={optionsSelesai.find(
                                    (option) =>
                                      option.value === totalPerHalamanSelesai
                                  )}
                                  onChange={handlePageSizeChangeSelesai}
                                  options={optionsSelesai}
                                  placeholder="Rows per page"
                                />
                              </Col>
                            </Row>
                          </Col>

                          <Col>
                            <ReactPaginate
                              forcePage={pageSelesai}
                              containerClassName="paginations justify-content-end"
                              previousClassName="pages-items-containers"
                              previousLinkClassName="pages-links-labels"
                              nextClassName="pages-items-containers"
                              nextLinkClassName="pages-links-labels"
                              breakClassName="pages-items-containers"
                              pageCount={po.listPoSelesai.totalPages}
                              pageRangeDisplayed={3}
                              marginPagesDisplayed={1}
                              pageLinkClassName="pages-links-labels"
                              pageClassName="pages-items-containers"
                              activeClassName="actives"
                              activeLinkClassName="activess"
                              nextLabel=" > "
                              previousLabel=" < "
                              initialPage={pageSelesai}
                              disableInitialCallback={true}
                              onPageChange={(val) => {
                                setPageSelesai(val.selected);
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>

      {/* Modal Detail */}
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Purchase Order
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kloter
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPo && detailPo.kloter}</Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kode Mesin
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPo && detailPo.produk.kodeProduk}</Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nama Mesin
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPo && detailPo.produk.namaProduk}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tipe Mesin
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPo && detailPo.produk.tipe}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kapasitas Mesin
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPo && detailPo.produk.kapasitas}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Jumlah Pesan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPo && detailPo.qty}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Jumlah Selesai
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailPo && detailPo.qtySelesai}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tanggal Order
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPo && !detailPo.tglOrder
                    ? "-"
                    : detailPo &&
                      moments
                        .tz(new Date(detailPo.tglOrder), "Asia/Jakarta")
                        .format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Deadline Pengerjaan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailPo && !detailPo.tglDeadline
                    ? "-"
                    : detailPo &&
                      moments
                        .tz(new Date(detailPo.tglDeadline), "Asia/Jakarta")
                        .format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tanggal Selesai
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {(detailPo && !detailPo.tglSelesai) ||
                  (detailPo && detailPo.tglSelesai === "")
                    ? "-"
                    : detailPo &&
                      moments
                        .tz(new Date(detailPo.tglSelesai), "Asia/Jakarta")
                        .format("DD-MM-YYYY")}
                </Col>
              </Row>

              <div
                style={{
                  maxHeight: "500px",
                  overflowY: "scroll",
                  marginTop: 20,
                }}
              >
                <h3>History</h3>
                <VerticalTimeline lineColor="#113946">
                  {detailPo &&
                    detailPo.history.map((item, index) => (
                      <VerticalTimelineElement
                        className="vertical-timeline-element--education"
                        contentStyle={{
                          borderTop: "7px solid  rgb(255, 175, 5)",
                          // item.status === "Menunggu Konfirmasi"
                          //   ? "7px solid  rgb(255, 175, 5)"
                          //   : item.status === "Diajukan"
                          //   ? "7px solid  rgb(186, 217, 253)"
                          //   : item.status === "Diajukan (File Gagal Terkirim)"
                          //   ? "7px solid  rgb(87, 41, 206)"
                          //   : item.status === "Diproses"
                          //   ? "7px solid  rgb(250, 93, 2)"
                          //   : item.status === "Disetujui"
                          //   ? "7px solid  rgb(90, 201, 103)"
                          //   : item.status === "Revisi"
                          //   ? "7px solid  rgb(255, 43, 75)"
                          //   : "7px solid  rgb(255, 15, 0)",
                        }}
                        key={index}
                        date={moment(item.tglUpdate).format(
                          "DD MMMM YYYY HH:mm:ss"
                        )}
                        iconStyle={{
                          background: "#FFAF05",
                          // item.status === "Menunggu Konfirmasi"
                          //   ? "#FFAF05"
                          //   : item.status === "Diajukan"
                          //   ? "#BAD9FD"
                          //   : item.status === "Diajukan (File Gagal Terkirim)"
                          //   ? "#5729CE"
                          //   : item.status === "Diproses"
                          //   ? "#fa5d02"
                          //   : item.status === "Disetujui"
                          //   ? "#5AC967"
                          //   : item.status === "Revisi"
                          //   ? "#FF2B4B"
                          //   : "#E10F00",
                          color: "#fff",
                        }}
                      >
                        <h3
                          className="vertical-timeline-element-title"
                          style={{ fontWeight: "bold" }}
                        >
                          Update {item.selesai} mesin
                        </h3>
                        <div style={{ marginTop: "15px" }}>
                          <label
                            style={{
                              textTransform: "none",
                              fontSize: 14,
                              color: "black",
                            }}
                          >
                            {item.keterangan}
                          </label>
                        </div>
                      </VerticalTimelineElement>
                    ))}
                </VerticalTimeline>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      <Modal
        size="sm"
        show={modalUpdatePo}
        onHide={() => {
          setModalUpdatePo(false);
          setSelesai(1);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Card className="stacked-form" style={{ margin: 10 }}>
          <Card.Header>
            <Card.Title as="h4" style={{ color: "black", fontWeight: "bold" }}>
              Update PO Mesin Selesai
              <hr></hr>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <div>
              Mesin dipesan : <b>{detailPo && detailPo.qty}</b>
            </div>
            <div>
              Mesin selesai : <b>{detailPo && detailPo.qtySelesai}</b>
            </div>
            <div>
              Mesin belum selesai :
              <b>{detailPo && detailPo.qty - detailPo.qtySelesai}</b>
            </div>
            {detailPo && detailPo.orderan.length > 0 ? (
              <div
                style={{
                  color: "red",
                  fontSize: 20,
                }}
              >
                Mesin Pesan Langsung :
                <b>{detailPo && detailPo.orderan.length}</b>
              </div>
            ) : null}

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                // backgroundColor: "red",
                textAlign: "center",
                justifyContent: "center",
                marginTop: 20,
                alignItems: "center",
              }}
            >
              <div>
                <Button
                  variant="info"
                  onClick={decSelesai}
                  style={{ color: "#000000" }}
                >
                  <b>-</b>
                </Button>
              </div>
              <div style={{ width: 80 }}>
                <span>{selesai}</span>
              </div>
              <div>
                <Button
                  variant="info"
                  onClick={incSelesai}
                  style={{ color: "#000000" }}
                >
                  <b>+</b>
                </Button>
              </div>
            </div>
          </Card.Body>

          <Card.Footer style={{ textAlign: "center" }}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                kirimUpdatePo();
              }}
              className="btn-wd"
              type="submit"
              // variant="info"
            >
              Update
            </Button>
          </Card.Footer>
        </Card>
      </Modal>

      {/* Modal Edit Stor */}
      <Modal
        size="md"
        show={modalStor}
        onHide={() => {
          setModalStor(false);
          setStatusStor("");
          setIdRekeningAsal("");
          setDokumen([]);
          setDokumenValidasi(false);
          setStatusStorValidasi(false);
          setDetailPo(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Update Status Pembayaran PO
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Status Pembayaran
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={statusStor}
                    onChange={(value) => {
                      setStatusStor(value);
                      setStatusStorValidasi(false);
                    }}
                    options={[
                      // { value: false, label: "Belum Dibayar" },
                      { value: true, label: "Sudah Dibayar" },
                    ]}
                    placeholder="Pilih Status Pembayaran"
                  />
                  {statusStorValidasi ? (
                    <label style={{ textTransform: "none", color: "red" }}>
                      *status pembayaran tidak boleh kosong
                    </label>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Pilih Rekening
                    <span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={idRekeningAsal}
                    onChange={(value) => {
                      setIdRekeningAsal(value);
                      setValidasiRekeningAsal(false);
                    }}
                    options={listRekening}
                    placeholder="Pilih Rekening"
                  />
                  {validasiRekeningAsal === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Rekening tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                <div>
                  <div>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Upload Dokumen{" "}
                      <span className="star" style={{ textTransform: "none" }}>
                        *{" "}
                        <span style={{ color: "blue" }}>
                          (Maksimal 2 Dokumen)
                        </span>
                      </span>
                    </label>
                  </div>

                  <label
                    className="label-input-file btn text-white mb-4"
                    htmlFor="import2"
                    style={{
                      fontWeight: "bold",
                      backgroundColor:
                        dokumen.length === 2 ? "grey" : "#447DF7",
                      border: 0,
                      marginTop: 10,
                    }}
                  >
                    <div
                      style={{
                        color: "#FFFFFF",
                        textTransform: "none",
                      }}
                    >
                      <i
                        className="fas fa-images"
                        style={{ marginRight: 5 }}
                      ></i>
                      Pilih File
                    </div>

                    <input
                      disabled={pending || dokumen.length === 2 ? true : false}
                      type="file"
                      id="import2"
                      onClick={(e) => {
                        e.target.value = "";
                      }}
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      accept=".jpg,.jpeg,.png"
                      multiple
                    />
                  </label>
                  {dokumenValidasi === false ? null : (
                    <label
                      className="error"
                      style={{
                        textTransform: "none",
                        color: "red",
                        marginLeft: 10,
                      }}
                    >
                      Dokumen tidak boleh kosong.
                    </label>
                  )}
                  {dokumen.length === 0 ? (
                    <div>
                      <p style={{ color: "GrayText" }}>File kosong</p>
                    </div>
                  ) : (
                    dokumen.map((file, index) => (
                      <div className="d-flex align-items-center" key={index}>
                        <p className="mb-1 mr-1">{file.name}</p>

                        <i
                          className="fa fa-times"
                          style={{
                            cursor: "pointer",
                            color: "red",
                            marginLeft: 10,
                          }}
                          onClick={() =>
                            pending ? null : removeDokumen(index)
                          }
                        />
                      </div>
                    ))
                  )}
                </div>
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  updateStor();
                }}
              >
                Update
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>
    </>
  );
}

export default Po;
