import React from "react";

// react-bootstrap components
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Modal,
  Form,
  Image,
  FormGroup,
  InputGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Select from "react-select";
// core components
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactDatetime from "react-datetime";
import moment from "moment";
import moments from "moment-timezone";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import {
  getAsset,
  updateKondisiAsset,
  getTotalNominalAsset,
  getKategoriAssetDropdown,
  getLokasiAssetDropdown,
  tambahAsset,
  exportAsset,
} from "stores";
import "./Pagination.css";
import CurrencyInput from "react-currency-input-field";
import NotificationAlert from "react-notification-alert";

function ListAsset() {
  const dispatch = useDispatch();
  const asset = useSelector((state) => state.assetReducer);
  const auth = useSelector((state) => state.authReducer);
  const [listAsset, setListAsset] = React.useState([]);
  const [tglAwal, setTglAwal] = React.useState(
    new Date(new Date().getFullYear(), 0, 1).setHours(0, 0, 0, 0)
  );
  const [tglAkhir, setTglAkhir] = React.useState(new Date());
  const notificationAlertRef = React.useRef(null);
  const [modal, setModal] = React.useState(false);
  const [modalEdit, setModalEdit] = React.useState(false);
  const [modalImage, setModalImage] = React.useState(false);
  const [detailAsset, setDetailAsset] = React.useState(null);
  const [keterangan, setKeterangan] = React.useState("");
  const [kondisiAsset, setKondisiAsset] = React.useState("");
  const [validasiKondisi, setValidasiKondisi] = React.useState(false);
  const [validasiKeterangan, setValidasiKeterangan] = React.useState(false);
  const [pending, setPending] = React.useState(false);
  const [totalPerHalaman, setTotalPerHalaman] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [searchText, setSearchText] = React.useState("");
  const [urlImage, setUrlImage] = React.useState("");
  const lokasiAsset = useSelector((state) => state.lokasiAssetReducer);
  const kategoriAsset = useSelector((state) => state.kategoriAssetReducer);
  const [modalAddAsset, setModalAddAsset] = React.useState(false);
  const [listKategoriAsset, setListKategoriAsset] = React.useState([]);
  const [listLokasiAsset, setListLokasiAsset] = React.useState([]);
  const [idKategori, setIdKategori] = React.useState("");
  const [validasiKategori, setValidasiKategori] = React.useState(false);
  const [idLokasi, setIdLokasi] = React.useState("");
  const [validasiLokasi, setValidasiLokasi] = React.useState(false);
  const [namaBarang, setNamaBarang] = React.useState("");
  const [validasiNamaBarang, setValidasiNamaBarang] = React.useState(false);
  const [harga, setHarga] = React.useState(0);
  const [validasiHarga, setValidasiHarga] = React.useState(false);
  const [tglAsset, setTglAsset] = React.useState(null);
  const [validasiTglAsset, setValidasiTglAsset] = React.useState(false);

  const exportAllAsset = async () => {
    Swal.fire({
      title: "Loading...!",
      text: "Download data asset sedang berlangsung,tunggu proses hingga selesai!",
      icon: "warning",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    exportAsset(dispatch, {
      tglAwal: moments
        .tz(new Date(tglAwal), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
      tglAkhir: moments
        .tz(new Date(tglAkhir), "Asia/Jakarta")
        .format("YYYY-MM-DD"),
    }).then((respon) => {
      if (respon.status === 200) {
        const url = window.URL.createObjectURL(new Blob([respon.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `Export Asset-${moments
            .tz(tglAwal, "Asia/Jakarta")
            .format("DD-MM-YYYY")} sampai ${moments
            .tz(tglAkhir, "Asia/Jakarta")
            .format("DD-MM-YYYY")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        Swal.fire({
          title: "Download Berhasil",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: !respon.data
            ? "Gagal download data asset"
            : respon.data.message,
        });
      }
    });
  };
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };
  const handleClearSearch = () => {
    setSearchText("");
  };
  const options = [
    { value: 10, label: "10" },
    { value: 50, label: "50" },
    { value: 100, label: "100" },
  ];
  const handlePageSizeChange = (selectedOption) => {
    setTotalPerHalaman(selectedOption.value);
  };

  const customStyles = {
    rows: {
      style: {
        marginTop: 5,
        marginBottom: 5,
        // height: 50, // override the row height
      },
    },
    headRow: {
      style: {
        minHeight: 35,
        backgroundColor: "#F8F8F8",
        // fontFamily: "Poppins",
        justifyContent: "center",
      },
    },
    headCells: {
      style: {
        minHeight: 50,
        fontWeight: "bold",
        fontSize: 14,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
    cells: {
      style: {
        minHeight: 50,
        "&:first-child": {
          borderRight: "1px solid #ccc", // Menambahkan garis pembatas di sebelah kanan sel pertama
        },
      },
    },
  };

  const columns = [
    {
      name: "No",
      width: "80px",
      center: true,
      selector: (row, index) => index + asset.listAsset.pagingCounter,
    },
    {
      name: "Aksi",
      width: "200px",
      center: true,
      selector: (row) => {
        return (
          <>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                onClick={() => {
                  setDetailAsset(row);
                  setModal(true);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={<Tooltip id="tooltip-48903503">Detail</Tooltip>}
                >
                  <i
                    style={{ color: "#447DF7", fontSize: 16 }}
                    className="fas fa-info-circle"
                  ></i>
                </OverlayTrigger>
              </div>
              <div
                onClick={() => {
                  setDetailAsset(row);
                  setModalEdit(true);
                }}
                style={{
                  width: 25,
                  height: 25,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  margin: 10,
                }}
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip id="tooltip-48903503">
                      Update Kondisi Asset
                    </Tooltip>
                  }
                >
                  <i
                    style={{ color: "#478964", fontSize: 16 }}
                    className="fas fa-edit"
                  ></i>
                </OverlayTrigger>
              </div>
            </div>
          </>
        );
      },
    },
    {
      name: "Kondisi Asset",
      width: "200px",
      center: true,
      selector: (row) =>
        row.softDelete ? (
          <div
            style={{
              backgroundColor: "rgba(251,59,70,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(251,59,70)",
            }}
          >
            <b style={{ color: "rgb(251,59,70)" }}>Tidak Digunakan</b>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "rgba(36,204,239,0.2)",
              padding: "5px 10px 5px 10px",
              borderRadius: 5,
              border: "1px solid rgb(36,204,239)",
            }}
          >
            <b style={{ color: "rgb(36,204,239)" }}>Digunakan</b>
          </div>
        ),
    },
    {
      name: "Kode Asset",
      width: "200px",
      center: true,
      selector: (row) => row.kodeAsset,
    },
    {
      name: "Nama Asset",
      width: "200px",
      center: true,
      selector: (row) => row.namaBarang,
    },
    {
      name: "Kategori Asset",
      width: "200px",
      center: true,
      selector: (row) => row.kategoriAsset[0].namaKategori,
    },
    {
      name: "Lokasi Asset",
      width: "200px",
      center: true,
      selector: (row) => row.lokasiAsset[0].namaLokasi,
    },
    {
      name: "Tanggal Asset Masuk",
      width: "200px",
      center: true,
      selector: (row) =>
        moments
          .tz(new Date(row.tglAssetMasuk), "Asia/Jakarta")
          .format("DD-MM-YYYY"),
    },
    {
      name: "Harga Asset",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.harga);
        return formattedValue;
      },
    },
    {
      name: "Pembagi (Bulan)",
      width: "200px",
      center: true,
      selector: (row) => row.kategoriAsset[0].pembagi,
    },
    {
      name: "Penyusutan per Bulan",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.penyusutan[0].hargaSusutPerBulan);
        return formattedValue;
      },
    },
    {
      name: "Akumulasi",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.penyusutan[0].akumulasiSusut);
        return formattedValue;
      },
    },
    {
      name: "Jumlah Asset Tetap",
      width: "200px",
      center: true,
      selector: (row) => {
        const formattedValue = new Intl.NumberFormat("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        }).format(row.penyusutan[0].totalSusut);
        return formattedValue;
      },
    },
  ];

  const history = useHistory();
  React.useEffect(() => {
    let tmp =
      asset.listAsset.data &&
      asset.listAsset.data.map((val, index) => {
        return {
          ...val,
          // totalOrders: {
          //   Progress: val.orders.filter(
          //     (order) => order.statusProduk === "Progress"
          //   ).length,
          //   Selesai: val.orders.filter(
          //     (order) => order.statusProduk === "Selesai"
          //   ).length,
          // },
        };
      });
    setListAsset(tmp);

    if (asset.listAsset.length != 0) {
      setPending(false);
    }
  }, [asset.listAsset]);

  React.useEffect(() => {
    if (searchText != "") {
      setPage(0);
    }
    getAsset(dispatch, {
      cari: searchText,
      page: page + 1,
      limit: totalPerHalaman,
      // tglAwal: moments
      //   .tz(new Date(tglAwal), "Asia/Jakarta")
      //   .format("YYYY-MM-DD"),
      // tglAkhir: moments
      //   .tz(new Date(tglAkhir), "Asia/Jakarta")
      //   .format("YYYY-MM-DD"),
    });
  }, [page, totalPerHalaman, searchText]);

  React.useEffect(() => {
    getTotalNominalAsset(dispatch);
    getKategoriAssetDropdown(dispatch);
    getLokasiAssetDropdown(dispatch);
  }, []);

  const updateKondisi = () => {
    if (kondisiAsset === "") {
      setValidasiKondisi(true);
    }

    if (kondisiAsset.value === "tidak") {
      if (keterangan === "") {
        setValidasiKeterangan(true);
      }
    }

    if (
      kondisiAsset === "" ||
      (kondisiAsset.value === "tidak" && keterangan === "")
    ) {
      notify({
        code: 1,
        message: "Periksa kembali isian anda jangan ada yang salah atau kosong",
      });
    } else {
      Swal.fire({
        title: "Apakah anda yakin untuk melakukan update kondisi asset?",
        text: "Pastikan semua isian anda sudah benar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Update!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading...!",
            text: "Update kondisi asset sedang diupdate,tunggu proses hingga selesai!",
            icon: "warning",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });

          let dataApproval = {
            // idPengajuan, note, statusPengajuan, kategori, pembagi,harga
            idAsset: detailAsset.idAsset,
            keterangan: keterangan,
            statusAsset: kondisiAsset.value,
          };

          updateKondisiAsset(dataApproval).then((response) => {
            if (response.data.status === 200) {
              setModalEdit(false);
              setPending(false);
              setDetailAsset(null);
              setKondisiAsset("");
              setKeterangan("");
              setValidasiKeterangan(false);
              setValidasiKondisi(false);
              getTotalNominalAsset(dispatch);
              getAsset(dispatch, {
                cari: searchText,
                page: page + 1,
                limit: totalPerHalaman,
                // tglAwal: moments
                //   .tz(new Date(tglAwal), "Asia/Jakarta")
                //   .format("YYYY-MM-DD"),
                // tglAkhir: moments
                //   .tz(new Date(tglAkhir), "Asia/Jakarta")
                //   .format("YYYY-MM-DD"),
              });
              Swal.fire({
                title: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              setPending(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: !response.data
                  ? "Gagal melakukan update"
                  : response.data.message,
              });
            }
          });
        }
      });
    }
  };

  React.useEffect(() => {
    let tmpKategori =
      kategoriAsset.listKategoriAssetDropdown.data &&
      kategoriAsset.listKategoriAssetDropdown.data.map((val, index) => {
        return {
          value: val.idKategoriAsset,
          label: `${val.namaKategori} - ${
            val.jenisBatas === "bawah" ? "Di Bawah" : "Di Atas"
          } ${new Intl.NumberFormat("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 0,
          }).format(val.batas)}`,
        };
      });

    let tmpLokasi =
      lokasiAsset.listLokasiAssetDropdown.data &&
      lokasiAsset.listLokasiAssetDropdown.data.map((val, index) => {
        return {
          value: val.idLokasi,
          label: val.namaLokasi,
        };
      });

    setListKategoriAsset(tmpKategori);
    setListLokasiAsset(tmpLokasi);
  }, [
    kategoriAsset.listKategoriAssetDropdown,
    lokasiAsset.listLokasiAssetDropdown,
  ]);

  const addAssetBaru = () => {
    // e.preventDefault();

    if (namaBarang === "") {
      setValidasiNamaBarang(true);
    }
    if (idKategori === "") {
      setValidasiKategori(true);
    }

    if (idLokasi === "") {
      setValidasiLokasi(true);
    }

    if (tglAsset === null) {
      setValidasiTglAsset(true);
    }

    if (
      harga === 0 ||
      harga === undefined ||
      harga === "" ||
      harga === "0" ||
      isNaN(parseInt(harga))
    ) {
      setValidasiHarga(true);
    }

    if (
      namaBarang === "" ||
      idKategori === "" ||
      idLokasi === "" ||
      harga === 0 ||
      harga === undefined ||
      harga === "" ||
      harga === "0" ||
      isNaN(parseInt(harga)) ||
      tglAsset === null
    ) {
      notify({
        code: 1,
        message: "Periksa kembali isian anda jangan ada yang salah atau kosong",
      });
    } else {
      Swal.fire({
        title: "Apakah anda yakin untuk menambahkan asset?",
        text: "Pastikan semua isian anda sudah benar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ajukan!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Loading...!",
            text: "Penambahan asset sedang dikirim,tunggu proses hingga selesai!",
            icon: "warning",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          let dataAddAsset = {
            namaBarang: namaBarang,
            idKategori: idKategori.value,
            idLokasi: idLokasi.value,
            harga: parseInt(harga),
            tglAssetMasuk: moments
              .tz(new Date(tglAsset), "Asia/Jakarta")
              .format("YYYY-MM-DD"),
          };

          tambahAsset(dataAddAsset).then((response) => {
            if (response.data.status === 200) {
              setModalAddAsset(false);
              setPending(false);
              setNamaBarang("");
              setIdKategori("");
              setValidasiKategori(false);
              setIdLokasi("");
              setValidasiLokasi(false);
              setHarga(0);
              setValidasiHarga(false);
              setTglAsset(null);
              setValidasiTglAsset(false);
              getAsset(dispatch, {
                cari: searchText,
                page: page + 1,
                limit: totalPerHalaman,
                // tglAwal: moments
                //   .tz(new Date(tglAwal), "Asia/Jakarta")
                //   .format("YYYY-MM-DD"),
                // tglAkhir: moments
                //   .tz(new Date(tglAkhir), "Asia/Jakarta")
                //   .format("YYYY-MM-DD"),
              });
              getTotalNominalAsset(dispatch);
              getKategoriAssetDropdown(dispatch);
              getLokasiAssetDropdown(dispatch);
              Swal.fire({
                title: response.data.message,
                icon: "success",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              setPending(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: !response.data
                  ? "Gagal melakukan penambahan"
                  : response.data.message,
              });
            }
          });
        }
      });
    }
  };

  const notify = (val) => {
    var type = val.code === 0 ? "info" : "danger";

    var options = {};
    options = {
      place: "tr",
      message: (
        <div>
          <div>
            <b style={{ color: "#000000" }}>{val.message}</b>
          </div>
        </div>
      ),
      type: type,
      icon: val.code === 0 ? "fas fa-box-open" : "fas fa-times-circle",
      autoDismiss: 5,
    };
    notificationAlertRef.current.notificationAlert(options);
  };
  return (
    <>
      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Container fluid>
        <Row
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 20,
          }}
        >
          <Col>
            <div style={{ fontSize: 25, fontWeight: "bold" }}>List Asset</div>
          </Col>
          <Col style={{ textAlign: "right" }}>
            {/* <Button
              style={{ marginLeft: 20, marginBottom: 0 }}
              onClick={() => {
                setModal(true);
              }}
            >
              Tambah Produk
            </Button> */}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Kode Asset/Nama Asset"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row>
        <Col md="12">
          <Row>
            {/* <Col md="2">
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                Tanggal Awal
              </div>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(0, 0, 0, 0),
                      setTglAwal(e.toDate().setHours(0, 0, 0, 0));
                  }}
                  value={tglAwal}
                  initialValue={tglAwal}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                ></ReactDatetime>
              </Form.Group>
            </Col>
            <Col md="2">
              <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                Tanggal Akhir
              </div>
              <Form.Group
                style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Date Picker Here",
                    readOnly: true,
                    style: {
                      cursor: "default",
                      color: "black",
                      backgroundColor: "white",
                    },
                  }}
                  onChange={(e) => {
                    e.toDate().setHours(23, 59, 0, 0),
                      setTglAkhir(e.toDate().setHours(23, 59, 0, 0));
                  }}
                  value={tglAkhir}
                  initialValue={tglAkhir}
                  timeFormat={false}
                  dateFormat="DD/MM/YYYY"
                ></ReactDatetime>
              </Form.Group>
            </Col> */}
            <Col md="3">
              <div
                style={{
                  marginBottom: 20,
                  alignItems: "center",
                }}
              >
                <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Total Nominal Asset Tetap
                </div>
                <div style={{ width: 250 }}>
                  <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                    {new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(
                      asset.nominalAsset && asset.nominalAsset.totalSusut
                    )}
                  </div>
                </div>
              </div>
              {/* <Button
                style={{
                  marginLeft: 20,
                  marginBottom: 0,
                  backgroundColor: "rgba(68,125,247,1)",
                  borderColor: "rgba(68,125,247,1)",
                }}
                onClick={() => {
                  // exportAllSj();
                  // setModalAddPengajuan(true);
                }}
              >
                <i
                  className="fas fa-file-invoice-dollar"
                  style={{ marginRight: 10 }}
                ></i>
                Tambah Asset
              </Button> */}
            </Col>
            <Col md="9" style={{ textAlign: "right" }}>
              <Button
                style={{
                  marginLeft: 20,
                  marginBottom: 0,
                  backgroundColor: "rgba(40,118,74,1)",
                  borderColor: "rgba(40,118,74,1)",
                }}
                onClick={() => {
                  exportAllAsset();
                }}
              >
                <i
                  className="fas fa-file-excel"
                  style={{ marginRight: 10 }}
                ></i>
                Export Asset
              </Button>
              <Button
                style={{
                  marginLeft: 20,
                  marginBottom: 0,
                  backgroundColor: "rgba(68,125,247,1)",
                  borderColor: "rgba(68,125,247,1)",
                }}
                onClick={() => {
                  // exportAllSj();
                  setModalAddAsset(true);
                }}
              >
                <i className="fas fa-plus" style={{ marginRight: 10 }}></i>
                Tambah Asset
              </Button>
            </Col>
          </Row>
        </Col>
        {/* <Row>
          <Col>
            <Button
              onClick={() => {
                setModalImport(true);
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(40,118,74,1)",
                borderColor: "rgba(40,118,74,1)",
              }}
            >
              <i className="fas fa-file-excel" style={{ marginRight: 10 }}></i>
              Import Excel
            </Button>
            <Button
              onClick={() => {
                window.open(
                  "https://docs.google.com/spreadsheets/d/1Qxfk5Xe0Xbjr39htdHLRoiA6_wSCEWaN/edit?usp=sharing&ouid=116475921970373937456&rtpof=true&sd=true",
                  "_blank"
                );
              }}
              className="btn-social"
              variant="twitter"
              style={{
                backgroundColor: "rgba(64,123,255,1)",
                borderColor: "rgba(64,123,255,1)",
                marginLeft: 10,
              }}
            >
              <i
                className="fas fa-cloud-download-alt"
                style={{ marginRight: 10 }}
              ></i>
              Download Format Import
            </Button>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginBottom: 20,
              }}
            >
              <div>
                <InputGroup>
                  <Form.Control
                    style={{
                      paddingLeft: "10px",
                      width: "300px",
                    }}
                    type="text"
                    value={searchText}
                    onChange={handleSearch}
                    placeholder="Nama Mesin/Kode Mesin"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text
                      style={{ cursor: "pointer" }}
                      onClick={handleClearSearch}
                    >
                      <i class="fas fa-times"></i>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </div>
            </div>
          </Col>
        </Row> */}

        <Row>
          <Col md="12">
            <Card>
              <Card.Body>
                <DataTable
                  columns={columns}
                  data={listAsset}
                  //   pagination
                  customStyles={customStyles}
                  paginationPerPage={totalPerHalaman}
                  //   onChangePage={handlePageChange}
                  progressPending={pending}
                />

                <div style={{ marginTop: 35 }}>
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Col>
                      <Row style={{ alignItems: "center" }}>
                        <div
                          style={{
                            paddingRight: 0,
                            marginLeft: 20,
                            marginRight: 10,
                          }}
                        >
                          <label
                            style={{
                              fontWeight: "bold",
                              textTransform: "none",
                              fontSize: 14,
                            }}
                          >
                            Rows per page
                          </label>
                        </div>
                        <Col sm="3" style={{ paddingLeft: 0 }}>
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="Pilih Client"
                            value={options.find(
                              (option) => option.value === totalPerHalaman
                            )}
                            onChange={handlePageSizeChange}
                            options={options}
                            placeholder="Rows per page"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <ReactPaginate
                        forcePage={page}
                        containerClassName="paginations justify-content-end"
                        previousClassName="pages-items-containers"
                        previousLinkClassName="pages-links-labels"
                        nextClassName="pages-items-containers"
                        nextLinkClassName="pages-links-labels"
                        breakClassName="pages-items-containers"
                        pageCount={asset.listAsset.totalPages}
                        pageRangeDisplayed={3}
                        marginPagesDisplayed={1}
                        pageLinkClassName="pages-links-labels"
                        pageClassName="pages-items-containers"
                        activeClassName="actives"
                        activeLinkClassName="activess"
                        nextLabel=" > "
                        previousLabel=" < "
                        initialPage={page}
                        disableInitialCallback={true}
                        onPageChange={(val) => {
                          setPage(val.selected);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Modal Detail */}
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setDetailAsset(null);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Asset
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kode Asset
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailAsset && detailAsset.kodeAsset}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Nama Asset
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">{detailAsset && detailAsset.namaBarang}</Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kondisi Asset
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailAsset && detailAsset.softDelete ? (
                    <div>
                      <b style={{ color: "rgb(251,59,70)" }}>Tidak Digunakan</b>
                    </div>
                  ) : (
                    <div>
                      <b style={{ color: "rgb(36,204,239)" }}>Digunakan</b>
                    </div>
                  )}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Kategori
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailAsset && detailAsset.kategoriAsset[0].namaKategori}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Lokasi
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailAsset && detailAsset.lokasiAsset[0].namaLokasi}
                </Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Harga
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailAsset &&
                    new Intl.NumberFormat("id-ID", {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }).format(detailAsset.harga)}
                </Col>
              </Row>

              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Tanggal Asset Masuk
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {detailAsset &&
                    moments
                      .tz(new Date(detailAsset.tglAssetMasuk), "Asia/Jakarta")
                      .format("DD-MM-YYYY")}
                </Col>
              </Row>
              <Row>
                <Col sm="6" style={{ fontWeight: "bold" }}>
                  Keterangan
                </Col>
                <Col sm="0.5">:</Col>
                <Col sm="5">
                  {(detailAsset && !detailAsset.keterangan) ||
                  (detailAsset && detailAsset.keterangan === "")
                    ? "-"
                    : detailAsset && detailAsset.keterangan}
                </Col>
              </Row>

              {/* <div style={{ marginTop: 20 }}>
                <b>Bukti Dokumen Pengajuan</b>
              </div>
              <hr></hr>
              <Row>
                {detailPengajuan &&
                  detailPengajuan.buktiFotoPengajuan.map((val, index) => {
                    return (
                      <>
                        <Col sm="4">
                          <Image
                            onClick={() => {
                              setUrlImage(val);
                              setModalImage(true);
                            }}
                            src={val}
                            style={{
                              objectFit: "contain",
                              width: "200px",
                              height: "300px",
                              cursor: "pointer",
                            }}
                            thumbnail
                          />
                        </Col>
                      </>
                    );
                  })}
              </Row> */}

              {/* <div style={{ marginTop: 20 }}>
                <b>Bukti Dokumen Approval</b>
              </div>
              <hr></hr>
              <Row>
                {detailPengajuan &&
                detailPengajuan.buktiFotoApprove.length === 0 ? (
                  <span style={{ color: "grey", marginLeft: 20 }}>
                    Belum memiliki dokumen approval.
                  </span>
                ) : (
                  detailPengajuan &&
                  detailPengajuan.buktiFotoApprove.map((val, index) => {
                    return (
                      <>
                        <Col sm="4">
                          <Image
                            onClick={() => {
                              setUrlImage(val);
                              setModalImage(true);
                            }}
                            src={val}
                            style={{
                              objectFit: "contain",

                              width: "200px",
                              height: "300px",
                              cursor: "pointer",
                            }}
                            thumbnail
                          />
                        </Col>
                      </>
                    );
                  })
                )}
              </Row> */}
            </Card.Body>
          </Card>
        </Col>
      </Modal>

      {/* Modal Add */}
      <Modal
        size="md"
        show={modalAddAsset}
        onHide={() => {
          setModalAddAsset(false);
          setPending(false);
          setNamaBarang("");
          setIdKategori("");
          setValidasiKategori(false);
          setIdLokasi("");
          setValidasiLokasi(false);
          setHarga(0);
          setValidasiHarga(false);
          setTglAsset(null);
          setValidasiTglAsset(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Form Tambah Asset
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Nama Barang <span className="star">*</span>
                  </label>
                  <Form.Control
                    onChange={(e) => {
                      setNamaBarang(e.target.value);
                      setValidasiNamaBarang(false);
                    }}
                    placeholder="Nama Barang"
                    value={namaBarang}
                  ></Form.Control>
                  {validasiNamaBarang === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Nama barang tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Pilih Kategori Asset<span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={idKategori}
                    onChange={(value) => {
                      setIdKategori(value);
                      setValidasiKategori(false);
                    }}
                    options={listKategoriAsset}
                    placeholder="Pilih Kategori"
                  />
                  {validasiKategori === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Kategori asset tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Pilih Lokasi Asset<span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={idLokasi}
                    onChange={(value) => {
                      setIdLokasi(value);
                      setValidasiLokasi(false);
                    }}
                    options={listLokasiAsset}
                    placeholder="Pilih Lokasi"
                  />
                  {validasiLokasi === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Lokasi asset tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>

                <Form.Group style={{ marginTop: 15 }}>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Harga Asset <span className="star">*</span>
                  </label>
                  <CurrencyInput
                    className="custom-input"
                    style={{
                      padding: 5,
                      borderRadius: 4,
                      width: "100%",
                      height: "40px",
                      borderWidth: "1px",
                      borderTopColor: "rgba(227, 227, 227, 0.3)",
                      borderLeftColor: "rgba(227, 227, 227, 0.3)",
                      borderBottomColor: "rgba(227, 227, 227, 1)",
                      borderRightColor: "rgba(227, 227, 227, 1)",
                    }}
                    name="nominal"
                    placeholder="Nominal Pengajuan"
                    prefix="Rp "
                    onValueChange={(value) => {
                      setHarga(value);
                      setValidasiHarga(false);
                    }}
                    defaultValue={harga}
                  />
                  {validasiHarga === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Harga asset tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>

                <div style={{ marginBottom: 10, fontWeight: "bold" }}>
                  Tanggal Asset Masuk
                </div>
                <Form.Group
                  style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
                >
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: "DD/MM/YYYY",
                      readOnly: true,
                      style: {
                        cursor: "default",
                        color: "black",
                        backgroundColor: "white",
                      },
                    }}
                    onChange={(e) => {
                      e.toDate().setHours(0, 0, 0, 0),
                        setTglAsset(e.toDate().setHours(0, 0, 0, 0));
                      setValidasiTglAsset(false);
                    }}
                    value={tglAsset}
                    initialValue={tglAsset}
                    timeFormat={false}
                    dateFormat="DD/MM/YYYY"
                  ></ReactDatetime>
                </Form.Group>
                {validasiTglAsset === false ? null : (
                  <label
                    className="error"
                    style={{ textTransform: "none", color: "red" }}
                  >
                    Tanggal asset masuk tidak boleh kosong.
                  </label>
                )}
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                // variant="info"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  addAssetBaru();
                  // buatPengajuan();
                }}
              >
                Submit
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* Modal Edit */}
      <Modal
        size="md"
        show={modalEdit}
        onHide={() => {
          setModalEdit(false);
          setPending(false);
          setDetailAsset(null);
          setKondisiAsset("");
          setKeterangan("");
          setValidasiKondisi(false);
          setValidasiKeterangan(false);
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Update Kondisi Asset
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form action="#" method="#">
                <Form.Group>
                  <label style={{ color: "black", fontWeight: "bold" }}>
                    Kondisi Asset <span className="star">*</span>
                  </label>

                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={kondisiAsset}
                    onChange={(value) => {
                      setKondisiAsset(value);
                      setValidasiKondisi(false);
                    }}
                    options={[
                      { value: "digunakan", label: "Digunakan" },
                      { value: "tidak", label: "Tidak Digunakan" },
                    ]}
                    placeholder="Pilih Kondisi Asset"
                  />
                  {validasiKondisi === false ? null : (
                    <label
                      className="error"
                      style={{ textTransform: "none", color: "red" }}
                    >
                      Kondisi asset tidak boleh kosong.
                    </label>
                  )}
                </Form.Group>
                {kondisiAsset.value === "digunakan" ||
                kondisiAsset === "" ? null : (
                  <Form.Group>
                    <label style={{ color: "black", fontWeight: "bold" }}>
                      Keterangan
                    </label>
                    <Form.Control
                      onChange={(e) => {
                        setKeterangan(e.target.value);
                        setValidasiKeterangan(false);
                      }}
                      placeholder="Keterangan"
                      type="text"
                      value={keterangan}
                    ></Form.Control>
                    {validasiKeterangan === true &&
                    kondisiAsset.value === "tidak" ? (
                      <label
                        className="error"
                        style={{ textTransform: "none", color: "red" }}
                      >
                        Keterangan tidak boleh kosong.
                      </label>
                    ) : null}
                  </Form.Group>
                )}
              </Form>
            </Card.Body>
            <Card.Footer style={{ textAlign: "end" }}>
              <Button
                className="btn-fill"
                type="submit"
                style={{ backgroundColor: "#00BFFF", border: 0 }}
                onClick={(e) => {
                  e.preventDefault();
                  updateKondisi();
                }}
              >
                Update
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Modal>

      {/* Modal show image */}
      <Modal
        size="lg"
        show={modalImage}
        onHide={() => {
          setModalImage(false);
          setUrlImage("");
        }}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton></Modal.Header>
        <Col md="12" style={{ marginTop: 20 }}>
          <Card className="stacked-form">
            <Card.Header>
              <Card.Title
                as="h4"
                style={{ color: "black", fontWeight: "bold" }}
              >
                Detail Gambar
                <hr></hr>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Image
                src={urlImage}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "100%",
                }}
                thumbnail
              />
            </Card.Body>
          </Card>
        </Col>
      </Modal>
    </>
  );
}

export default ListAsset;
