const initialState = {
  listKomponenLaba: [],
  listKomponenLabaDropDown: [],
};

const komponenLabaReducer = (state = initialState, action) => {
  let { type, data } = action;
  switch (type) {
    case "SET_ALLKOMPONENLABA":
      return {
        ...state,
        listKomponenLaba: data,
      };
    case "SET_ALLKOMPONENLABADROPDOWN":
      return {
        ...state,
        listKomponenLabaDropDown: data,
      };
    default:
      return state;
  }
};
export default komponenLabaReducer;
