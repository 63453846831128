import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getKomponenLaba(dispatch, data, history) {
  try {
    const response = await baseAxios("/komponenLabaWeb/getKomponenLaba", {
      headers: { token: localStorage.getItem("token") },
    });
    dispatch({ type: "SET_ALLKOMPONENLABA", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getKomponenLabaDropdown(dispatch, data, history) {
  try {
    const response = await baseAxios(
      "/komponenLabaWeb/getKomponenLabaDropdown",
      {
        headers: { token: localStorage.getItem("token") },
      }
    );
    dispatch({ type: "SET_ALLKOMPONENLABADROPDOWN", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahKomponenLaba(data) {
  try {
    const response = await baseAxios.post(
      "/komponenLabaWeb/addKomponenLaba",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updateKomponenLaba(data) {
  try {
    const response = await baseAxios.patch(
      "/komponenLabaWeb/updateKomponenLaba",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function assignKomponenLaba(data) {
  try {
    const response = await baseAxios.patch(
      "/komponenLabaWeb/assignKomponenLaba",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function hapusAssignKomponenLaba(data) {
  try {
    const response = await baseAxios.patch(
      "/komponenLabaWeb/hapusAssignKomponenLaba",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function deleteKomponenLaba(data) {
  try {
    const response = await baseAxios.patch(
      "/komponenLabaWeb/deleteKomponenLaba",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}
