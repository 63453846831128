import { baseAxios, errorHandler } from "../index";
import Swal from "sweetalert2";
import routes from "../../routes";

export async function getPengajuanAsset(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/assetWeb/getPengajuanAsset?page=${data.page}&limit=${data.limit}&cari=${data.cari}&tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLPENGAJUANASSET", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function tambahPengajuanAsset(data) {
  try {
    const response = await baseAxios.post("/assetWeb/addPengajuanAsset", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function getAsset(dispatch, data, history) {
  try {
    const response = await baseAxios(
      `/assetWeb/getAsset?page=${data.page}&limit=${data.limit}&cari=${data.cari}`,
      {
        headers: { token: localStorage.getItem("token") },
      }
    );

    dispatch({ type: "SET_ALLASSET", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function getTotalNominalAsset(dispatch) {
  try {
    const response = await baseAxios(`/assetWeb/totalNominalAsset`, {
      headers: { token: localStorage.getItem("token") },
    });

    dispatch({ type: "SET_TOTALNOMINALASSET", data: response.data });
  } catch (err) {
    return err.response;
  }
}

export async function approvePengajuanAsset(data) {
  try {
    const response = await baseAxios.post(
      "/assetWeb/approvePengajuanAsset",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function approveMasukAsset(data) {
  try {
    const response = await baseAxios.post("/assetWeb/approveMasukAsset", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function updateKondisiAsset(data) {
  try {
    const response = await baseAxios.post(
      "/assetWeb/updateKondisiAsset",
      data,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
      }
    );
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function tambahAsset(data) {
  try {
    const response = await baseAxios.post("/assetWeb/addAsset", data, {
      headers: {
        token: localStorage.getItem("token"),
      },
    });
    return response;
  } catch (err) {
    return err.response;
  }
}

export async function exportPengajuanAsset(dispatch, data) {
  try {
    const response = await baseAxios(
      `/assetWeb/exportPengajuanAsset?tglAwal=${data.tglAwal}&tglAkhir=${data.tglAkhir}`,
      {
        headers: {
          token: localStorage.getItem("token"),
        },
        responseType: "blob",
      }
    );

    return response;
  } catch (err) {
    return err.response;
  }
}

export async function exportAsset(dispatch, data) {
  try {
    const response = await baseAxios(`/assetWeb/exportAsset`, {
      headers: {
        token: localStorage.getItem("token"),
      },
      responseType: "blob",
    });

    return response;
  } catch (err) {
    return err.response;
  }
}
