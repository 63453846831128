import axios from "axios";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { thunk } from "redux-thunk";

// reducer
import authReducer from "./auth";
import dashboardReducer from "./dashboard";
import divisiReducer from "./divisi";
import jabatanReducer from "./jabatan";
import roleReducer from "./role";
import karyawanReducer from "./karyawan";
import produkReducer from "./produk";
import orderReducer from "./order";
import invoiceReducer from "./invoice";
import suratJalanReducer from "./suratJalan";
import pesanLangsungReducer from "./pesanLangsung";
import poReducer from "./po";
import kebutuhanReducer from "./kebutuhan";
import rekeningReducer from "./rekening";
import pengajuanReducer from "./pengajuan";
import kategoriAssetReducer from "./kategoriAsset";
import lokasiAssetReducer from "./lokasiAsset";
import assetReducer from "./asset";
import komponenLabaReducer from "./komponenLaba";

// function
export * from "./auth/function";
export * from "./divisi/function";
export * from "./jabatan/function";
export * from "./role/function";
export * from "./karyawan/function";
export * from "./produk/function";
export * from "./order/function";
export * from "./invoice/function";
export * from "./suratJalan/function";
export * from "./pesanLangsung/function";
export * from "./dashboard/function";
export * from "./po/function";
export * from "./kebutuhan/function";
export * from "./rekening/function";
export * from "./pengajuan/function";
export * from "./errorHandler";
export * from "./kategoriAsset/function";
export * from "./lokasiAsset/function";
export * from "./asset/function";
export * from "./komponenLaba/function";
// server api
// export const baseUrl = process.env.REACT_APP_URL
export const baseUrl = "https://apibackoffice.baserjp.com/";
// export const baseUrl = "http://52.221.194.5:9030/"; //backupserver
// dev
// export const baseUrl = "https://apidev.baserjp.com/";
// export const baseUrl = "https://888jk4wb-9030.asse.devtunnels.ms/";
// export const baseUrl = "http://localhost:9030/";
// export const baseUrl = "http://192.168.110.160:9030/";
// export const baseUrl = "http://192.168.100.40:9030/";
// export const baseUrl = "http://192.168.251.90:9030/";

export const baseAxios = axios.create({
  baseURL: baseUrl,
  // timeout: 10000,
});

const rootReducer = combineReducers({
  authReducer,
  dashboardReducer,
  divisiReducer,
  jabatanReducer,
  roleReducer,
  karyawanReducer,
  produkReducer,
  orderReducer,
  invoiceReducer,
  suratJalanReducer,
  pesanLangsungReducer,
  dashboardReducer,
  poReducer,
  kebutuhanReducer,
  rekeningReducer,
  pengajuanReducer,
  kategoriAssetReducer,
  lokasiAssetReducer,
  assetReducer,
  komponenLabaReducer,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
